import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
    fillable_by:FillableBy.Both,
    title:
        "Clinical Competency Checklist for CSZ Hemotherm 400MR Heater-Cooler Device",
    sections: [
        // {...employeeinfo},
        {
            title: "Device Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs visual inspection of the device for cleanliness and integrity.",
                "Verifies power connections and confirms that the device powers on correctly.",
                "Assembles and connects all required tubing and accessories per procedure guidelines.",
                "Fills water reservoir with sterile or distilled water as per the manufacturer's recommendations.",
                "Configures temperature settings based on clinical requirements."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Operation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Turns on the device and ensures all system checks are completed successfully.",
                "Monitors water levels and temperature during operation, adjusting as necessary.",
                "Maintains desired patient temperature range throughout the procedure.",
                "Responds promptly and appropriately to alarms or operational irregularities.",
                "Demonstrates the ability to perform an emergency shutdown if required.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Safety and Infection Control",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Ensures only sterile or distilled water is used to prevent contamination.",
                "Positions the device to minimize potential risks to the patient and staff.",
                "Follows protocols for routine water exchange to reduce microbial growth.",
                "Adheres to infection control practices as outlined by the manufacturer and facility guidelines.",
                "Communicates infection control practices effectively to team members."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Cleaning and Maintenance",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs routine cleaning and disinfection of the device as per manufacturer guidelines.",
                "Logs all cleaning and disinfection activities in the maintenance records.",
                "Replaces filters and other consumables as required.",
                "Demonstrates knowledge of using approved cleaning agents and equipment.",
                "Maintains a complete maintenance log for compliance with regulatory standards.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Documentation",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Records pre-use system checks and initial temperature settings.",
                "Logs all cleaning, maintenance, and disinfection tasks in the designated logbook.",
                "Documents any operational issues or alarms for review and troubleshooting.",
                "Maintains accurate records of water changes and filter replacements.",
                "Ensures all documentation is completed in compliance with facility and regulatory standards."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields:getSignatures(FillableBy.Both)
        },
    ],
}